import * as React from "react"
import Img from "gatsby-image"

import "../styles/main.scss"
import Logo from "./logo"
import { Link } from "gatsby"

export default class Navbar extends React.Component {
    props: {
    }

    constructor(props) {
        super(props)
    }

    public render() {

        return (
            <nav>
                <div className="nav-line">
                    <Logo size={25}/>
                </div>
                <div>
                    <Link to={"/"}><b>AJA Morrison</b></Link>
                </div>
                <div>
                    <Link to={"/contact"}>Contact</Link>
                </div>
            </nav>
        )
    }
}