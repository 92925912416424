import * as React from "react"

import "../styles/main.scss"

export default class Logo extends React.Component {

    props: {
        size: number;
    }
    gridSize: number;


    constructor(props) {
        super(props)
        this.gridSize = this.props.size/10
    }
    
    public render() {
        return (
            <svg style={{width: `${this.props.size}px`, height: `${this.props.size}px`}} width={this.props.size} height={this.props.size}>         	
                <path 
                    d={`
                        M ${this.gridSize*(1)} ${this.gridSize*5}  
                        L ${this.gridSize*(2)} ${this.gridSize*3}  
                        L ${this.gridSize*(4)} ${this.gridSize*7} 
                        L ${this.gridSize*(6)} ${this.gridSize*3}  
                        L ${this.gridSize*(4)} ${this.gridSize*3}  
                        M ${this.gridSize*(6)} ${this.gridSize*7}  
                        L ${this.gridSize*(8)} ${this.gridSize*3}  
                        L ${this.gridSize*(9)} ${this.gridSize*5}  
                    `}
                    fill="none"
                    strokeWidth={this.gridSize}
                /> 
                <path
                    d={`
                        M ${this.gridSize*(0.694)} ${this.gridSize*4.5} 
                        L ${this.gridSize*(0.200)} ${this.gridSize*5.5} 
                        L ${this.gridSize*(1.000)} ${this.gridSize*5.5} 

                        M ${this.gridSize*(4.000)} ${this.gridSize*2.5} 
                        L ${this.gridSize*(3.150)} ${this.gridSize*2.5} 
                        L ${this.gridSize*(3.600)} ${this.gridSize*3.3} 

                        M ${this.gridSize*(6.000)} ${this.gridSize*7.5} 
                        L ${this.gridSize*(5.200)} ${this.gridSize*7.5} 
                        L ${this.gridSize*(5.640)} ${this.gridSize*6.6} 

                        M ${this.gridSize*(9.000)} ${this.gridSize*5.5} 
                        L ${this.gridSize*(9.830)} ${this.gridSize*5.5} 
                        L ${this.gridSize*(9.400)} ${this.gridSize*4.7} 
                    `}
                    stroke="none"
                />
            </svg>
        )
    }
}