import * as React from "react"
import { graphql, Link } from "gatsby"
import ArticleLink from "../components/article-link"
import Thumbnail from "../components/thumbnail"
import Logo from "../components/logo"
import Navbar from "../components/nav-bar"
import Helmet from "react-helmet";
import GridTile from "../components/grid-tile"

export default class Layout extends React.Component {
    props: {
        children: any;
    }
    constructor(props) {
        super(props)
    }
    public render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>AJA M-</title>
                    <link rel="canonical" href="http://ajamorrison.com/" />
                    <link rel="preconnect" href="https://fonts.googleapis.com"/>
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={"anonymous"}/>
                    <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;600&display=swap" rel="stylesheet"/>
                    <script type="text/javascript" src="/muuri.js"></script>
                </Helmet>  
                <Navbar/>
                <main>
                    {this.props.children}
                </main>
            </div>
        )
    }
}